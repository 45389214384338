//TODO:  include test
import React, { useState, useCallback, useMemo } from 'react';
import Button from '../../components/Button';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import {
  useGetKnockoutQuery,
  useSendKnockoutScreenAnswersMutation,
} from '../../services/knockoutSlice';
import { LoadingContainer } from '../../containers/LoadingContainer';
import StepperDots from '../../components/StepperDots';
import { useTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import DataForm from './DataForm';
import { KnockoutScreenWithDataFields } from '../../../shared/types/Knockout';
import { ApmScreen } from './ApmScreen';
import { useUpdateSepMutation, useGetSepQuery } from '../../services/sepSlice';
import useMapData from '../../hooks/useMapData';
import { NewWorkflowType } from '../../../shared/types/SEP';


const Knockout = () => {
  const param = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  let sepId = 0;

  if (param.sepId !== undefined) sepId = +param.sepId;

  const {
    data: getKnockoutScreensRes,
    isSuccess,
    isLoading,
    error,
  } = useGetKnockoutQuery(sepId);
  const { data: sep } = useGetSepQuery(sepId);
  const [sendKnockoutScreenAnswers, { isLoading: isSaving }] =
    useSendKnockoutScreenAnswersMutation();
  const [currentScreenIdx, setCurrentScreenIdx] = useState(-1);
  const [apmScreenIdx, setApmScreenIdx] = useState(-2);
  const [updateSep] = useUpdateSepMutation();
  const [alternateContactScreen,setAlternateContactScreen] = useState(false)
  const [knockoutScreens, setKnockoutScreens] = React.useState<
    KnockoutScreenWithDataFields[] | null
  >(null);

  const currentScreen =
    currentScreenIdx === -1 || !knockoutScreens
      ? null
      : knockoutScreens[currentScreenIdx];

  const goToOverview = useCallback(
    () => navigate(`/sep/${sepId}`, { replace: true }),
    [navigate, sepId]
  );

  let dataFields: any = [];

  const indexOfCurrentScreen = knockoutScreens?.findIndex(
    (screen: any, index: number) => //index !== apmScreenIdx && 
    screen?.id === currentScreen?.id
  );

  const handleBackButtonClick = () => {
    if ((alternateContactScreen && currentScreenIdx === 1) || (!alternateContactScreen && currentScreenIdx === 2)) {
      // We're on the first knockout screen, so redirect to the "Create screen" so the user can edit their SEP name & description

      navigate('/create', { state: { sepId, condition: 'edit' } });
    } else {
      // Go to previous knockout screen
      setCurrentScreenIdx(currentScreenIdx - 1);
    }
  };

  dataFields = useMemo(
    () =>{
     return( currentScreen &&
        [...currentScreen.knockoutScreenDataFields].sort(
          (a, b) => a.dataFieldLocation.order - b.dataFieldLocation.order
        ))
    }
    
     ,
    [currentScreen]
  );

  const knockout = true;
  const { data, setData, buttonValidation } = useMapData({
    dataFields,
    knockout,
  });

  const handleSubmit = async (e: any, apm?: any) => {
   // if (currentScreenIdx !== apmScreenIdx) {
      if(currentScreen?.name.includes("Name and Description")) {
      const userinputs: any = data && data?.find((item: any) =>  item.type = 'input')
      const userinputsDesc: any = data && data[1]?.value
      updateSep({ id: sepId, body: {name: userinputs?.value, description: userinputsDesc}}).unwrap();
      }
      if (currentScreen) {
        const filteredData = data?.filter((item: any) => item?.type !== 'textbox');
        const { data: updatedScreens } = (await sendKnockoutScreenAnswers({
          knockoutScreenID: currentScreen.id,
          dataFields: filteredData,
        })) as { data: KnockoutScreenWithDataFields[] };
        if (updatedScreens) {
          const firstIncompleteScreenIdx = updatedScreens.findIndex(
            (screen: any, index: number) => !screen.complete
          );
          const originalAltContactIndex = updatedScreens.findIndex(
            (screen: any, index: number) => screen.name.includes('Name and Description')
          );
          if (firstIncompleteScreenIdx >= 0) {
            setKnockoutScreens(reorder(updatedScreens));
            setData([]);
            // if(currentScreenIdx === (apmScreenIdx -1)) setCurrentScreenIdx(currentScreenIdx + 1)
            // else {
              if (firstIncompleteScreenIdx < originalAltContactIndex || originalAltContactIndex == -1)
                setCurrentScreenIdx(firstIncompleteScreenIdx + 2)
              else setCurrentScreenIdx(firstIncompleteScreenIdx + 1)
              if(firstIncompleteScreenIdx === 1 && currentScreen.name.includes('Name and Description')){
                setCurrentScreenIdx(currentScreenIdx + 1)
              }
              if(currentScreenIdx >= 2) {
                setCurrentScreenIdx(currentScreenIdx + 1)
              }
            // }
          } else if (currentScreenIdx === 1) {
            setCurrentScreenIdx(currentScreenIdx + 1)
          } else {
            // The user still has some knockout screens to answer.
            goToOverview();
          }
        }
      }
    // } else {
    //   const apmID = apm === '' ? null : parseInt(apm);

    //   updateSep({
    //     id: sepId,
    //     body: { apmID: apmID },
    //   });
    //   setCurrentScreenIdx(currentScreenIdx + 1);
    // }
  };

  const reorder = (array: any) => {
    const newArray: any = [];
    //let index = apmScreenIdx + 1;
    const IntialIndexValue = getKnockoutScreensRes?.some((screen: any)=> screen.name.includes('Name and Description')) && getKnockoutScreensRes?.some((sec: any) => sec.name.includes('alternate contact'))
    let index = IntialIndexValue ? 3 : 2
    // let index =2;
    array.forEach((item: any) => {
      if(item.name.includes('Name and Description')){
        newArray[1] = item;
      }else if(item.name.includes('alternate contact')){
        newArray[2] = item;
      }else{
        newArray[index] = item;
      index++;
      }
      
    });
    return newArray;
  };

  React.useEffect(() => {
    const alternateContactScreen = getKnockoutScreensRes?.findIndex((screen: any)=> screen.name.includes('Name and Description'))
    if (alternateContactScreen !== -1 )setAlternateContactScreen(true) //setApmScreenIdx(2)
    if (getKnockoutScreensRes) {
      setKnockoutScreens(reorder(getKnockoutScreensRes));
     // We've received the knockout screens for the first time when this component was mounted. Let's jump to the first incomplete screen.
      const firstIncompleteScreenIdx = getKnockoutScreensRes.findIndex(
        (screen: any, index: number) => !screen.complete
      );

      if (firstIncompleteScreenIdx >= 0) {
        location?.state?.slice(1) === 'create'
          ? setCurrentScreenIdx(1)
          : setCurrentScreenIdx(firstIncompleteScreenIdx + 2); // setCurrentScreenIdx(firstIncompleteScreenIdx + apmScreenIdx+ 1);
        if(firstIncompleteScreenIdx === 0) {
          setCurrentScreenIdx(1)
        }
        } else if (firstIncompleteScreenIdx === -1) {
        setCurrentScreenIdx(1)
      } else {
        // All of our knockout screens are complete. Redirect to SEP Overview.
        goToOverview();
      }
    }
  }, [getKnockoutScreensRes, goToOverview, location?.state]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size={100} />
      </LoadingContainer>
    );
  } else if (
    getKnockoutScreensRes === error &&
    getKnockoutScreensRes === undefined
  ) {
    return (
      <LoadingContainer>
        <Typography>No SEP Found</Typography>
      </LoadingContainer>
    );
  // } else if (currentScreenIdx === apmScreenIdx) {
  //   return (
  //     <>
  //       <ApmScreen
  //         knockoutScreens={knockoutScreens}
  //         indexOfCurrentScreen={indexOfCurrentScreen}
  //         apmScreenIdx={apmScreenIdx}
  //         handleBackButtonClick={handleBackButtonClick}
  //         isSaving={isSaving}
  //         handleSubmit={handleSubmit}
  //         sepId={sepId}
  //       />
  //     </>
  //   );
  } else if (isSuccess && knockoutScreens && knockoutScreens.length > 0) {
    return (
      <>
        <Box mt={5} />
        <Typography sx={{ font: 'normal normal 600 28px/38px Open Sans;' }}>
          {currentScreen?.name?.includes("Name and Description") ? `${sep?.workFlowType} ${currentScreen?.name}` : currentScreen?.name}
        </Typography>
        <Typography
          mt={3}
          mb={4}
          sx={{
            font: 'normal normal normal 20px/27px Open Sans;',
            color: theme.palette.gray.solidGray,
            maxWidth: 600,
            wordBreak: 'break-word',
          }}
        >
          <span
            className="knockout-description"
            dangerouslySetInnerHTML={{
              __html: currentScreen?.description ?? '',
            }}
          />
        </Typography>
        <DataForm
          dataFields={dataFields}
          data={data}
          setData={setData}
          knockout
          currentScreen={currentScreen}
        />
        {/** LOWER BUTTON CONTAINER */}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ diplay: 'flex', minWidth: '100%', px: 6 }}>
          <StepperDots
            steps={knockoutScreens.length}
            activeStep={indexOfCurrentScreen}
          />
          <Divider sx={{ backgroundColor: 'black' }} />
          <Box
            sx={{
              display: 'flex',
              mt: 3,
              justifyContent: 'space-between',
              px: 2,
            }}
          >
            <Button size="large" onClick={handleBackButtonClick}>
              <Box pr={1}>
                <i className="fa-regular fa-angle-left"></i>
              </Box>
              Back
            </Button>

            <Button
              size="large"
              variant="contained"
              disabled={isSaving || buttonValidation}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </>
    );
  } else {
    return <>Something went wrong...</>;
  }
};
export default Knockout;
