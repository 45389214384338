import React, { useState, useRef, useMemo } from 'react';
import PopOver from '../../components/PopOver/PopOver';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import PhaseProgress from './PhaseProgress';
import { TaskExtended } from '../../../shared/types/Task';
import { Link } from 'react-router-dom';
import {
  useGetSepQuery,
  useGetSepTasksQuery,
  useUpdateSepRequestorMutation,
} from '../../services/sepSlice';
import {
  useGetTaskTemplatesQuery,
} from '../../services/tasksSlice/tasksSlice';
import DataClassificationFields from '../../components/DataClassificationFields';
import APMFields from '../../components/APMFields';
import ModalWidget from '../../components/ModalWidget/ModalWidget';
import './styles.css';

import UserSearchBar from '../../components/TaskOverview/AutoComplete';
import { User } from '../../../shared/types/User';
import { useAccount } from '@azure/msal-react';
import { env } from '../../../shared/env';
import EditSEP from './EditSEP';
import { useGetDataFieldTemplatesQuery } from '../../services/dataFieldsSlice';
import { useGetDepartmentsQuery, useGetDefaultStakeholdersForSEPQuery } from '../../services/departmentSlice/departmentSlice';
import {useGetLatestActivityQuery} from '../../services/activitySlice/activitySlice'
import {GetWorkflowTemplate} from '../../utils/workflowTemplate'
const ProgressGraphic = require('../../assets/img/sepProgressGraphic.png');


interface GroupTasks {
  initiate: TaskExtended[];
  design: TaskExtended[];
  implement: TaskExtended[];
}
interface SepHeaderProps {
  dataFields: any;
  sepID: number;
  roleList: any;
}

const SepHeader = ({dataFields, sepID, roleList }: SepHeaderProps) => {
  const theme = useTheme();
  const { data: sep } = useGetSepQuery(sepID);
  const [sepEdit, setSepEdit] = useState<boolean>(false);
  const { data: adminKeyTopics } = useGetDataFieldTemplatesQuery();
  const { data: departments } = useGetDepartmentsQuery();
  const { data: defaultStakeholders } = useGetDefaultStakeholdersForSEPQuery(sepID);
  const { data: latestSepActivity } = useGetLatestActivityQuery(sepID);
  const sepData = useGetSepQuery(sepID);
  const createdById = sepData.data?.createdBy;
  const creatorId = sepData.data?.creator.id;
  const editPermission =
    roleList && roleList.length
      ? roleList?.includes('AuthSuperUser') ||
        roleList?.includes('AuthSolutionArchitect') ||
        roleList?.includes('AuthPortfolioOwner') ||
        roleList?.includes('AuthSupply') ||
        roleList?.includes('AuthNuclearCyberSecurity') ||
        roleList?.includes('AuthThirdPartySecurity') ||
        roleList?.includes('AuthSecurity') ||
        roleList?.includes('AuthEA') ||
        roleList?.includes('AuthLegal')
        ? true
        : false
      : false;
  const apm = React.useMemo(() => {
    return sep?.apmData ? JSON.parse(sep?.apmData as string) : null;
  }, [sep]);
  const { data: tasks } = useGetSepTasksQuery(sepID);
  const { data: taskTemplates } = useGetTaskTemplatesQuery();
  const groupByPhase: GroupTasks = useMemo(() => {
    const filteredTasks = tasks
      ?.filter((t) => t.enabled)
      .filter((t, index, self) => self.findIndex((task) => task.id === t.id) === index);
  
    return filteredTasks && filteredTasks.length !== 0
      ? groupBy(filteredTasks, 'phase')
      : [];
  }, [tasks]);

  const statusForSummary = ['todo','changesRequested', 'inReview'];
  const tasksForSummary = tasks
  ?.filter((t)=>{ return  t.enabled && statusForSummary.includes(t.status)
  })
  // const taskTemplateForSummary: (any)[] = [] 
  // tasksForSummary?.forEach((s)=>{
  //   if (s.taskTemplateID ){
  //     taskTemplateForSummary.push (taskTemplates?.filter((t)=>t.id === s.taskTemplateID)[0])
  //   }
  // })
  const getSummary = ()=> {
    const summary: any[] = [];
    tasksForSummary?.forEach((task, i)=>{
      if (task.taskTemplateID ){
        if(task?.assignedUserID === task?.defaultReviewerID){
          //if (taskTemplateForSummary[i]?.stakeholderSummary) summary.push(taskTemplateForSummary[i]?.stakeholderSummary)
          const stakeholderSummary = taskTemplates?.filter((t)=>t.id === task.taskTemplateID)[0]?.stakeholderSummary
          if (stakeholderSummary) summary.push(stakeholderSummary)
        }else {
          //if (taskTemplateForSummary[i]?.requestorSummary) summary.push(taskTemplateForSummary[i]?.requestorSummary)
          const requestorSummary = taskTemplates?.filter((t)=>t.id === task.taskTemplateID)[0]?.requestorSummary
          if (requestorSummary) summary.push(requestorSummary)
        } 
      }else{
        const dept = departments?.filter((d:any)=>d.id === task.departmentID)[0]
        if(task?.assignedUserID === task?.defaultReviewerID){
          if (!summary.includes(`${dept?.name} reviewing information provided by requestor.`))
          summary.push(`${dept?.name} reviewing information provided by requestor.`)
        }else if (task?.assignedUserID === creatorId){
          if (!summary.includes(`Requestor gathering information for ${dept?.name}`))
          summary.push(`Requestor gathering information for ${dept?.name}`)
        }else{
          if (!summary.includes(`SME being consulted to support ${dept?.name} Review.`))
          summary.push(`SME being consulted to support ${dept?.name} Review.`)
        }
      }
      
    })
    return summary
  }
  const getKeyTopics = () => {
    const keyTopicsData = dataFields?.filter((data: any) => data?.name === 'Key Topics');
    const adminkeyTopics = adminKeyTopics?.find((data: any) => data?.name === 'Key Topics');
    const finalResult: { reviewStatement: string; color: string }[] = [];

    const filterAdminKeyTopics = adminkeyTopics && adminkeyTopics?.dataFieldOptions.filter((priorityValue: any) => {
        return priorityValue.colors !== null && priorityValue.priorityTopics !== null;
    });

    const userSelectedKeyTopics = keyTopicsData && keyTopicsData[0]?.dataFieldOptions;

    if (filterAdminKeyTopics && userSelectedKeyTopics) {
        userSelectedKeyTopics.forEach((option: any) => {
            if (option?.selected) {
                const selectedTopic = option?.value;
                filterAdminKeyTopics?.forEach((priorityValue: any) => {
                    if (priorityValue?.priorityTopics.trim().replace(/\s+/g, ' ') === selectedTopic) {
                        finalResult?.push({ reviewStatement: priorityValue?.reviewStatements, color: priorityValue?.colors });
                    }
                });
            }
        });
    }
    return finalResult;
}

const workflowData: any = GetWorkflowTemplate('Workflow data field', sep?.workFlowType);
const designReview: any = sep?.workFlowType === null && GetWorkflowTemplate('Workflow data field', "SEP Design Review");
const powerBiLink = workflowData?.powerbiLink?.replace('{sepID}', sepID)
const designReviewLink = designReview?.powerbiLink?.replace('{sepID}', sepID)
const isLinkValid = powerBiLink || designReviewLink;

const replaceStakeholdersSummary = (desc: any, responseData: any) => {
  const placeholderRegex = /{([^{}]+?)stakeholder}/gi;
  const replacePlaceholder = (match: any, placeholderName: any) => {
    const normalizedPlaceholder = normalizeString(placeholderName);
    const matchingDepartment = responseData?.find(
      (item: any) => {
        const normalizedDepartmentName = normalizeString(item?.department?.name);
        return normalizedDepartmentName === normalizedPlaceholder
      });
    return matchingDepartment && matchingDepartment?.user && matchingDepartment?.user?.givenName ? `<b>${matchingDepartment?.user?.givenName} ${matchingDepartment?.user?.surname}</b>` :  "<b>User assigment is still pending</b>"
  };
  const normalizeString = (str:any) => {
      return str.toLowerCase().replace(/[^a-z0-9]/g, '')
  }
  const replacedDescriptions = desc?.map((descript: any) => {
    return descript?.replace(placeholderRegex, replacePlaceholder)
  })
  return replacedDescriptions
  }
  const summaryDesc = getSummary()
  const replacedDesc = replaceStakeholdersSummary(summaryDesc, defaultStakeholders)

  const completeInitiatePhase =
    tasks?.length !== 0 && groupByPhase.initiate !== undefined
      ? (groupBy(groupByPhase.initiate, 'status').complete as TaskExtended[])
      : null;
  const completeDesignPhase =
    tasks?.length !== 0 && groupByPhase.design !== undefined
      ? (groupBy(groupByPhase.design, 'status').complete as TaskExtended[])
      : null;
  const completeImplementPhase =
    tasks?.length !== 0 && groupByPhase.implement !== undefined
      ? (groupBy(groupByPhase.implement, 'status').complete as TaskExtended[])
      : null;

  function groupBy(objectArray: any[], property: string) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  }

  const sepFinish = sep?.phase === 'complete';

  // If current user is the requestor or admin, show edit icon
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
  const [isSepRequestor, setIsSepRequestor] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  // Target box container for user search layout
  const requestorRef = useRef(null);
  const [openRequestorEdit, setOpenRequestorEdit] = useState(false);
  const [updateSepRequestor] = useUpdateSepRequestorMutation();

  const [showSummary, setshowSummary] = useState<boolean>(false);

  // Account is for azure login, token, tenantId, etc.
  const account = useAccount();
  const tokenId = account?.idTokenClaims?.oid;

  // const sepData = useGetSepQuery(sepID);
  // const createdById = sepData.data?.createdBy;
  // const creatorId = sepData.data?.creator.id;

  // Original creator is the requestor
  const handleChangeUser = (type: string, user: User) => {
    // UserSearchBar requires a type, though it's not needed here
    if (type === 'reviewer') {
      // Saving the requestor will update the createdBy field in SEP table to be the new user
      updateSepRequestor({
        id: sepID,
        body: {
          id: user.id,
          displayName: user.displayName,
          givenName: user.givenName,
          surname: user.surname,
          email: user.email,
        },
      });
      setOpenRequestorEdit(false);
    }
  };

  useMemo(() => {
    // Current user is the requestor
    if (tokenId === createdById || tokenId === creatorId) {
      setIsCurrentUser(true);
      setIsSepRequestor(true);
    }
    // Current user is a local dev admin
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      setIsAdmin(true);
      return ['AuthSuperUser'];
    }
    // Determine if user is AuthSuperUser
    if (account?.idTokenClaims?.roles?.includes('AuthSuperUser')) {
      setIsAdmin(true);
      return account?.idTokenClaims?.roles?.includes('AuthSuperUser');
    }
  }, [tokenId, createdById, creatorId, account]);
  function handlePhaseClick(): void {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <ModalWidget
        isOpen={sepEdit}
        children={<EditSEP sep={sep} dataFields={dataFields} onClose={() => setSepEdit(false)} />}
        title="Edit SEP Detail"
        onClose={() => setSepEdit(false)}
      />
      <Box sx={{ padding: '30px 30px 10px 30px' }}>
        <Box display="flex">
          <Box>
            <Typography
              sx={{
                font: ' normal normal 600 25px/34px Open Sans;',
              }}
            >
              {sep?.name}
            </Typography>
            <Typography
              sx={{
                font: 'normal normal normal 18px/24px Open Sans;',
                color: '#7E8083',
                mt: 1,
              }}
            >
              {`SEP ${sepID}`}
            </Typography>
            <Typography
              sx={{
                font: 'normal normal normal 18px/24px Open Sans;',
                color: '#7E8083',
                mt: 2,
                mb: 2
              }}
            >
             Request Type: {sep?.workFlowType ? sep?.workFlowType : 'SEP Design Review'}
            </Typography>
            <Box sx={{flexDirection:'row', display:'flex'}}>
            <Typography
              sx={{
                font: 'normal normal normal 14px/24px Open Sans;',
                color: '#646669;',
                textTransform: 'capitalize',
              }}
            >
              {`SEP Status: ${sep?.status}`}
            </Typography>
              {getSummary().length > 0 &&
                <Button
                  variant="outlined"
                  onClick={() => setshowSummary(!showSummary)}
                  sx={{
                    marginLeft: '25px',
                    textTransform: 'none',
                    font: 'normal normal 600 12px/17px Open Sans;',
                  }}
                >
                  {showSummary ? `Hide Summary` : `Show Summary`}
                </Button>
              }
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '40px', marginTop: '10px', width: '350px' }}>
            {getKeyTopics()?.map((s, index) => {
              const style = {
              padding: '5px',
              paddingLeft: '10px',
              backgroundColor: s?.color,
              marginBottom: '10px',
              marginLeft: '10px'
             };
              return (
                  <div key={index} style={style}>
                      <b> {s?.reviewStatement} </b>
                  </div>
              );
              })}
            </div>
              
          </Box>
          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            {editPermission && (
              <Button
                variant="text"
                onClick={() => setSepEdit(true)}
                sx={{
                  textTransform: 'none',
                  font: 'normal normal 600 12px/17px Open Sans;',
                }}
              >
                Edit SEP Header
              </Button>
            )}
            <Button
              variant="text"
              component={Link}
              to={`/details/${sepID}`}
              sx={{
                textTransform: 'none',
                font: 'normal normal 600 12px/17px Open Sans;',
              }}
            >
              View SEP Details
            </Button>
            <Typography
              sx={{
                font: 'normal normal normal 10px/22px Open Sans;',
                color: '#646669;',
              }}
            >
              {latestSepActivity &&
                `Last Saved: ${format(
                  parseISO(latestSepActivity[0][0]?.lastUpdate as string),
                  'Pp'
                )}`}
            </Typography>
          </Box>
        </Box>
        {showSummary && 
            <Box sx={{border:"1px solid gray", marginY: '15px',padding: '10px'}}>
              <b>Summary</b><br/>
              {/* <ul>{getSummary().map((s)=>{return <li>{s}</li>})}</ul> */}
              <ul>{replacedDesc.map((s:any, index: any)=>(
                 <li key ={index} dangerouslySetInnerHTML={{ __html: s ?? '' }} />))}
              </ul>
            </Box>
          }
          <br />
        <Grid container spacing={0}>
          <Grid
            container
            item
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'flex-end' }}
            xs={12}
            md={12}
            lg={6}
          >
            <Grid item md={3} sx={{ minHeight:'80px' }}>
              <p className="sep-label">
                Requestor
                {(isAdmin || (isCurrentUser && isSepRequestor)) && (
                  <span>
                    &nbsp;&nbsp;
                    <Link to="#">
                      <i
                        className="fa-solid fa-pencil"
                        style={{ color: '#7E8083' }}
                        onClick={() => setOpenRequestorEdit(true)}
                      />
                    </Link>
                  </span>
                )}
              </p>
              <Box ref={requestorRef} sx={{ marginLeft: 1 }}>
                <UserSearchBar
                  type="reviewer"
                  show={openRequestorEdit}
                  // adAppRoleName is null to show any user
                  anchor={requestorRef.current}
                  showConfirmButton={true}
                  onClose={() => {
                    setOpenRequestorEdit(false);
                  }}
                  onChange={(user: User) => handleChangeUser('reviewer', user)}
                />
              </Box>
             
              <p className="sep-data">
                {sep?.creator?.givenName && sep?.creator?.surname
                  ? `${sep?.creator?.givenName} ${sep?.creator?.surname}`
                  : sep?.creator?.displayName}
                &nbsp;&nbsp;
                <a href={`mailto:${sep?.creator.email}`}>
                  <i
                    className="fa-solid fa-envelope"
                    style={{ color: '#7E8083' }}
                  />
                </a>
              </p>
            </Grid>
            <Grid item md={2} sx={{ minHeight:'80px' }}>
              <p className="sep-label">Request Date</p>
              <p className="sep-data">
                {format(parseISO(sep?.createdAt as string), 'P')}
              </p>
            </Grid>
            <Grid item md={2} sx={{ minHeight:'80px' }}>
              <p className="sep-label">APM ID</p>
              <div className="showInfo">
                <div>{sep?.apmID ?? 'No APM ID'} &nbsp;</div>
                {sep?.apmID && <PopOver component={<APMFields apm={apm} />} />}
              </div>
            </Grid>
            <Grid item md={3} sx={{ minHeight:'80px' }}>
              <p className="sep-label">
                {' '}
                Data Classification &nbsp;&nbsp;
                <i
                  className="fa-solid fa-shield-halved"
                  style={{ color: theme.palette.solidBlue.main }}
                ></i>
              </p>
              <div className="showInfo">
                <div>
                  {apm?.data_classification ?? 'No Data Classification'} &nbsp;
                </div>
                {apm?.data_classification && (
                  <PopOver
                    component={<DataClassificationFields data={apm} />}
                  />
                )}
              </div>
            </Grid>
            <Grid item md={2} sx={{ minHeight:'80px' }}>
              <p className="sep-label">Phase</p>
              <p className="sep-data">{(sep as {phase?: string})?.phase == "knockout" ? "Draft" : sep?.phase}</p>
            </Grid>
          </Grid>
          { ((designReview?.sepProgressBar && sep?.workFlowType === null) || workflowData?.sepProgressBar) && (
          <Grid
            container
            item
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            sm={12}
            md={12}
            lg={6}
          >
            <Grid item md={12} sx={{ width: '100%' }}>
            <a style={{display:'flex',justifyContent:'space-between'}} href={isLinkValid ? (powerBiLink || designReviewLink) : undefined} target="_blank" rel="noopener">
              <p className="sep-label" style={{alignSelf:'flex-end'}}>My Request Progress</p>
              <div><img
              src={ProgressGraphic}
              height="100px"
              alt="Constellation"
              style={{ marginTop: '5px', display: 'block' }}
            />
            <p className="sep-label" style={{alignSelf:'flex-end'}}>Click here for Progress Details</p>
            </div>
            </a>
            </Grid>
            <Grid item md={4} sx={{ flex: 1 }}>
              <PhaseProgress
                title="Initiate Phase"
                sepPhase={
                  groupByPhase.initiate !== undefined
                    ? groupByPhase.initiate
                    : null
                }
                completedTasks={completeInitiatePhase}
                phaseTitle="Initiate Phase"
                onClick={handlePhaseClick}
              />
            </Grid>
            <Grid item md={4} sx={{ flex: 1 }}>
              <PhaseProgress
                title="Design Phase"
                sepPhase={
                  groupByPhase.design !== undefined ? groupByPhase.design : null
                }
                completedTasks={completeDesignPhase}
                phaseTitle="Design Phase"
                onClick={handlePhaseClick}
              />
            </Grid>
            <Grid item md={4} sx={{ flex: 1 }}>
              <PhaseProgress
                title="Implement Phase"
                sepPhase={
                  groupByPhase.implement !== undefined
                    ? groupByPhase.implement
                    : null
                }
                completedTasks={completeImplementPhase}
                sepFinish={sepFinish}
                phaseTitle="Implement Phase"
                onClick={handlePhaseClick}
              />
            </Grid>
          </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default SepHeader;
