import React, { Fragment, useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Divider,
} from '@mui/material';
import { SEPSearchRow, SEPStatus, WORKFLOWTYPE, NewWorkflowType } from '../../../shared/types/SEP';
import { TaskStatus } from '../../../shared/types/Task';
import { StyledCheckBox } from '../SepFilterBar/SepFilterBar';
import { Link } from 'react-router-dom';
import UserDisplay from '../UserDisplay/UserDisplay';
import { getTaskProgress, getStatus, HeadCell } from './SepTableBody';
import PhaseProgress from '../../features/SepOverview/PhaseProgress';
import TasksTable from '../Tables/TasksTables';
import { useGetSepTasksQuery } from '../../services/sepSlice';
import SepToolbar from '../Toolbar/Toolbar';
import { useGetUserQuery } from '../../services/usersSlice';
import SepContacts from './SepContacts';
import {GetWorkflowTemplate} from '../../utils/workflowTemplate'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#646669',
    color: '#FBFCFC',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.gray.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  '&': {
    backgroundColor: '#fff',
    //borderTop: '1px solid rgba(224,224,224,1)'
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.solidBlue.main,
  '&:hover': {
    color: theme.palette.gray.main,
  },
}));

const formatTime = (dateStr: string) => {
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(5, 7);
  const day = dateStr.slice(8, 10);
  return `${month}/${day}/${year}`;
};

const SepDetail = ({ sep }: { sep: SEPSearchRow }) => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: tasks } = useGetSepTasksQuery(sep.id);
  const { data: loggedInUser, isLoading } = useGetUserQuery('me');
  const [filterTasks, setFilterTasks] = useState(true);
  const [hideOthers, setHideOthers] = useState(true);
  const [filterOthers, setFilterOthers] = useState(true);
  const [hidePendingTasks, setHidePendingTasks] = useState(true);
  const [hidePendingOtherTasks, setHidePendingOtherTasks] = useState(true);

  useEffect(()=> {
    if(sep?.creator?.id !== loggedInUser?.id && !isLoading) {
      setHidePendingTasks(false)
      setHidePendingOtherTasks(false)
      setFilterOthers(false)
      setFilterTasks(false)
      setFilterOthers(false)
    }
  },[sep, loggedInUser])
  
  return (
    <Box sx={{ width: '90%' }}>
      <Box sx={{ pb: 6 }}>
        {tasks && <SepContacts sep={sep} tasks={tasks} />}
      </Box>
      <Box sx={{ pb: 10 }}>
        <Box sx={{ mb: 4 }}>
          <SepToolbar
            title="My Tasks"
            caption="These tasks are to be completed by you. Click on a task for more information"
            filter={filterTasks}
            onChange={() => setFilterTasks(!filterTasks)}
            hideFilterChange={() => setHidePendingTasks(!hidePendingTasks)}
            hidePendingTasks={hidePendingTasks}
          />
          <Divider />
            <TasksTable
              sepID={sep.id}
              tasks={tasks?.filter((e: any) => {
                if(hidePendingTasks && filterTasks) {
                  return e.enabled && e.status !== TaskStatus.pending && e.status !== TaskStatus.complete && e.assignedUserID === loggedInUser?.id
                } else if(hidePendingTasks) {
                  return e.enabled && e.status !== TaskStatus.pending && e.assignedUserID === loggedInUser?.id
                } else if(filterTasks) {
                  return e.enabled && e.status !== TaskStatus.complete && e.assignedUserID === loggedInUser?.id
                } else {
                  return e.enabled && e.assignedUserID === loggedInUser?.id
                }
              }) || []
              }
            />
        </Box>
      </Box>
      <Box>
        <Box sx={{ mb: 4 }}>
          <SepToolbar
            title="Tasks for Others"
            caption="These tasks are to be completed by others but feel free to expand this area and see what everyone else is up to"
            hidable
            filter={filterOthers}
            hide={hideOthers}
            onChange={() => setFilterOthers(!filterOthers)}
            handleHide={() => setHideOthers(!hideOthers)}
            hideFilterChange={() => setHidePendingOtherTasks(!hidePendingOtherTasks)}
            hidePendingTasks={hidePendingOtherTasks}
          />
        </Box>
        {hideOthers ? (
            <TasksTable
              sepID={sep.id}
              tasks={tasks?.filter((e: any) => {
                if(hidePendingOtherTasks && filterOthers) {
                  return e.enabled && e.status !== TaskStatus.pending && e.status !== TaskStatus.complete && e.assignedUserID !== loggedInUser?.id
                } else if(hidePendingOtherTasks) {
                  return e.enabled && e.status !== TaskStatus.pending && e.assignedUserID !== loggedInUser?.id
                } else if(filterOthers) {
                  return e.enabled && e.status !== TaskStatus.complete && e.assignedUserID !== loggedInUser?.id
                } else {
                  return e.enabled && e.assignedUserID !== loggedInUser?.id
                }
              }) || []
              }
            />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

const withComponent = (str: string, phase: string) => {
  if (
    phase === 'pending' ||
    phase === 'waiting' ||
    window.location.href.replaceAll('/', '').slice(-8) === 'all-seps'
  ) {
    return (
      <Box
        sx={{
          border: '2px solid #FF7400',
          borderRadius: '20px',
          px: '12px',
          py: '6px',
          width: 'fit-content',
          position: 'relative',
        }}
      >
        {str}
      </Box>
    );
  } else if (phase === 'todo') {
    return (
      <Box
        sx={{
          border: '2px solid #FF7400',
          borderRadius: '18px',
          px: '12px',
          py: '6px',
          width: 'fit-content',
          position: 'relative',
          paddingLeft: '40px',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FF7400',
            width: '35px',
            height: '35px',
            borderRadius: '18px',
            position: 'absolute',
            top: '-2px',
            left: '-1px',
            textAlign: 'center',
            paddingTop: '10px',
          }}
        >
          <i className="fa-solid fa-flag" style={{ color: 'white' }}></i>
        </Box>
        {str}
      </Box>
    );
  } else {
    <Box
      sx={{
        border: '2px solid #FF7400',
        borderRadius: '20px',
        px: '12px',
        py: '6px',
        width: 'fit-content',
        position: 'relative',
      }}
    >
      {str}
    </Box>;
  }
};

const SepTableRow = ({
  row,
  onSelect,
  checked,
  onDetailShow,
  detailShow,
  headers,
}: {
  row: SEPSearchRow;
  onSelect: (isChecked: boolean) => void;
  checked: boolean;
  onDetailShow: (isShow: boolean) => void;
  detailShow: boolean;
  headers: HeadCell[];
}) => {
  const sx = detailShow
    ? {
        position: 'sticky',
        top: 76,
        zIndex: 800,
        backgroundColor: 'white',
        borderTop: '1px solid rgba(224,224,224,1)',
      }
    : {};
  function handlePhaseClick(): void {
    throw new Error('Function not implemented.');
  }

  const workflowData: any = GetWorkflowTemplate('Workflow data field', row?.workFlowType);
  const designReview: any = GetWorkflowTemplate('Workflow data field', "SEP Design Review");
  return (
    <>
      <StyledTableRow
        hover
        aria-label={`SEPs Row ${row.name}`}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        sx={sx}
      >
        <StyledTableCell
          variant="head"
          sx={{
            left: 0,
            maxWidth: '44px',
            minWidth: '44px',
            zIndex: 800,
            //...sx,
          }}
        >
          <i
            className={`fa-sharp fa-solid fa-chevron-${
              detailShow ? 'up' : 'down'
            }`}
            onClick={() => onDetailShow(!detailShow)}
          ></i>
        </StyledTableCell>
        <StyledTableCell
          variant="head"
          sx={{
            left: 44,
            maxWidth: '53px',
            minWidth: '53px',
            zIndex: 800,
            //...sx,
          }}
        >
          <StyledCheckBox
            indeterminate={false}
            checked={checked}
            onChange={(event) => {
              onSelect(event.target.checked);
            }}
            ariaLabel=""
            label={<></>}
          />
        </StyledTableCell>
        {headers
          .filter((e) => e.visible)
          .map((header) => (
            <Fragment key={header.id}>
              {header.id === 'id' && (
                <StyledTableCell
                  aria-label="SEP Id"
                  variant="head"
                  sx={{
                    left: 96.5,
                    maxWidth: '93px',
                    minWidth: '93px',
                    zIndex: 800,
                    //...sx,
                  }}
                >
                  <StyledLink to={`/sep/${row.id}`}>{row.id}</StyledLink>
                </StyledTableCell>
              )}
              {header.id === 'name' && (
                <StyledTableCell
                  sx={{ minWidth: '250px', zIndex: 500 }}
                  aria-label="SEP Name"
                  variant="head"
                >
                  <LightTooltip title={row.name}>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        fontSize: '14px',
                      }}
                    >
                      {row.name}
                    </Typography>
                  </LightTooltip>
                </StyledTableCell>
              )}
              {header.id === 'taskProgress' && (
                <StyledTableCell
                  sx={{ minWidth: '300px', zIndex: 500 }}
                  aria-label="Tasks Progress"
                >
                  {withComponent(getTaskProgress(row), getStatus(row))}
                </StyledTableCell>
              )}
              {header.id === 'requestor' && (
                <StyledTableCell
                  sx={{ minWidth: '220px', zIndex: 500 }}
                  aria-label="Assigned"
                >
                  <UserDisplay
                    showPicture
                    showName
                    userID={row.creator.id}
                    user={row.creator}
                  />
                </StyledTableCell>
              )}
              {header.id === 'created_at' && (
                <StyledTableCell
                  sx={{ minWidth: '160px', zIndex: 500 }}
                  aria-label="Requested_date"
                >
                  {formatTime(row.createdAt)}
                </StyledTableCell>
              )}
              {header.id === 'status' && (
                <StyledTableCell
                  sx={{
                    minWidth: '160px',
                    zIndex: 500,
                    textTransform: 'capitalize',
                  }}
                  aria-label="SEP Status"
                >
                  {row.status === 'active' ? 'In Progress' : row.status}
                </StyledTableCell>
              )}
              {header.id === 'phase' && (
                <StyledTableCell
                  sx={{ minWidth: '160px', zIndex: 500 }}
                  aria-label="Tasks DependentTaskCount"
                >
                  {[
                    SEPStatus.cancelled,
                    SEPStatus.expired,
                    SEPStatus.rejected,
                  ].some((status) => status === row.status)
                    ? 'Complete' 
                    : row.phase === 'knockout' ? 'Draft'
                    : row.phase.slice(0, 1).toUpperCase() + row.phase.slice(1)}
                </StyledTableCell>
              )}
              {header.id === 'workFlowType' && (
                <StyledTableCell
                  sx={{ minWidth: '160px', zIndex: 500 }}
                  aria-label="Tasks DependentTaskCount"
                >
                  {row?.workFlowType ? row?.workFlowType : "SEP Design Review"}
                </StyledTableCell>
              )}
              {header.id === 'requestProgress' && ((designReview?.sepProgressBar && row?.workFlowType === null) || workflowData?.sepProgressBar) && (
                <StyledTableCell
                  sx={{ minWidth: '500px', zIndex: 500 }}
                  aria-label="Phase Progress"
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box>
                    <PhaseProgress
                  title={`Initiate Phase`}
                  sepPhaseLen={row.initiateTasksTotalCount || 0}
                  completedLen={row.initiateTasksCompleteCount || 0}
                  phaseTitle="Initiate Phase"
                  onClick={handlePhaseClick}
                />
                    </Box>
                    <Box sx={{ marginLeft: '24px' }}>
                      <PhaseProgress
                        title={`Design Phase`}
                        sepPhaseLen={row.designTasksTotalCount || 0}
                        completedLen={row.designTasksCompleteCount || 0}
                        phaseTitle="Design Phase"
                        onClick={handlePhaseClick}
                      />
                    </Box>
                    <Box sx={{ marginLeft: '24px' }}>
                      <PhaseProgress
                        title={`Implement Phase`}
                        sepPhaseLen={row.implementTasksTotalCount || 0}
                        completedLen={row.implementTasksCompleteCount || 0}
                        phaseTitle="Implement Phase"
                        onClick={handlePhaseClick}
                      />
                    </Box>
                  </Box>
                </StyledTableCell>
              )}
            </Fragment>
          ))}
      </StyledTableRow>
      {detailShow && (
        <TableRow>
          <StyledTableCell colSpan={11}>
            <SepDetail sep={row} />
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};

export default SepTableRow;