// import { useGetDataFieldTemplatesQuery } from "../services/dataFieldsSlice";
// const { data: dataFieldTempaltes } = useGetDataFieldTemplatesQuery();

// export const getWorkflowTemplate = (name: string, value: any) => {
//   return dataFieldTempaltes
//     ?.find((data: any) => data?.name === name)
//     ?.dataFieldOptions.find((val: any) => val?.value === value);
// };

import { useGetDataFieldTemplatesQuery } from "../services/dataFieldsSlice";

export const GetWorkflowTemplate = (name: string, value: any) => {
  const { data: dataFieldTempaltes } = useGetDataFieldTemplatesQuery();

  return dataFieldTempaltes
    ?.find((data: any) => data?.name === name)
    ?.dataFieldOptions.find((val: any) => val?.value === value);
};
