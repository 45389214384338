import { KnockoutFollowupExtended, KnockoutTaskFollowup } from './Knockout';
import { SequelizeTimestamps } from './Sequelize';

export enum DataFieldType {
  input = 'input',
  textarea = 'textarea',
  number = 'number',
  email = 'email',
  emailLookup = 'emailLookup',
  apmLookup = 'apmLookup',
  select = 'select',
  multiSelect = 'multiSelect',
  date = 'date',
  dateTime = 'dateTime',
  yesNo = 'yesNo',
  checkbox = 'checkbox',
  toggle = 'toggle',
  tileSelect = 'tileSelect',
  tileMultiSelect = 'tileMultiSelect',
  textbox = 'textbox'
}
export enum DataFieldLocationType {
  'None' = '',
  'Department' = 'Department',
  'DepartmentReview' = 'DepartmentReview',
  'KnockoutScreen' = 'KnockoutScreen',
  'Task' = 'Task',
}

export enum DataFieldsCreateSEPEmail {
  Vendor = 'Vendor',
  VendorContact = 'Vendor Contact',
  VendorContactEmail = 'Vendor Contact Email',
  VendorTaxIdentificationNumber = 'Vendor Tax Identification Number (TIN)',
  BusinessUnit = 'Business Unit',
  IsNewOrExistingApplication = 'Is this an existing application or a new application?',
}

export interface DataField extends SequelizeTimestamps {
  id: number;
  createdBy: string;
  sepID: number;
  readOnly: boolean;
  template: boolean;
  required: boolean;
  order: number;
  name: string;
  description?: string;
  type: DataFieldType;
  dataFieldTemplateID?: number;
  value?: string | number | boolean;
  icon?: string;
  colors?: any;
  priorityTopics?: string;
  reviewStatements?: string;
  multiselectTooltipValue?: string;
  sepProgressBar?: boolean;
  powerbiLink?: string;
}
export interface DataFieldLocation extends SequelizeTimestamps {
  id: number;
  createdBy: string;
  sepID: number;
  dataFieldID: number;
  locationID: number;
  locationType: DataFieldLocationType;
  required: boolean;
  readOnly: boolean;
  template: boolean;
  order: number;
  dataFieldLocationTemplateID?: number;
}
export interface DataFieldWithOptions extends DataField {
  dataFieldOptions: DataFieldOption[];
}
export interface DataFieldWithOptionsAndLocations extends DataField {
  dataFieldOptions: DataFieldOption[];
  dataFieldLocations: DataFieldLocation[];
}

export interface DataFieldWithOptionsAndSingleLocation extends DataField {
  dataFieldOptions: DataFieldOption[];
  dataFieldLocation: DataFieldLocation;
}

export interface DataFieldWithOptionsAndLocationsAndKnockoutFollowupTasks
  extends DataFieldWithOptionsAndLocations {
  knockoutTaskFollowups: KnockoutTaskFollowup[];
}

export interface DataFieldOption extends SequelizeTimestamps {
  id: number;
  value: string;
  selected: boolean;
  dataFieldID: number;
  dataFieldOptionTemplateID?: number;
  createdBy: string;
  template: boolean;
  order: number;
  sepID: number;
  description?: string;
  icon?: string;
  colors?: string;
  priorityTopics?: string;
  reviewStatements?: string;
  multiselectTooltipValue?: string;
  sepProgressBar?: boolean;
  powerbiLink?: string
}

export interface DataFieldOptionUpdate {
  /** The DataFieldOption id */
  id: number;
  selected: boolean;
}

export interface DataFieldOptionUpdateByName {
  /** The DataFieldOption id */
  value: string;
  selected: boolean;
}
export interface DataFieldUpdate {
  /** The DataField id */
  id: number;
  /** Leave undefined for select/multi-select fields */
  value?: string | number | boolean;
  dataFieldOptions?: DataFieldOptionUpdate[];
}

export interface DataFieldUpdateByName {
  /** The DataField id */
  name: string;
  /** Leave undefined for select/multi-select fields */
  value?: string | number | boolean;
  dataFieldOptions?: DataFieldOptionUpdateByName[];
}

export interface DataFieldLocationUpdate {
  dataFieldLocationID: number;
  order: number;
}

export interface DataFieldExtendedWithSingleLocationAndFollowups
  extends DataField {
  dataFieldOptions: DataFieldOption[];
  dataFieldLocation: DataFieldLocation;
  knockoutFollowups: KnockoutFollowupExtended[];
}

export interface CreateDataFieldTemplateBody {
  name: string;
  description?: string;
  type: DataFieldType;
  dataFieldOptions?: CreateDataFieldOptionTemplateBody[];
}
export interface CreateDataFieldOptionTemplateBody {
  value: any;
  dataFieldID: number;
  order: number;
  description?: string;
  icon?: string;
  colors?: string;
  priorityTopics?: string;
  reviewStatements?: string;
  multiselectTooltipValue?: string;
  sepProgressBar?: boolean;
  powerbiLink?: string;
}
export interface CreateDataFieldLocationTemplateBody {
  dataFieldID: number;
  locationID: number;
  locationType: DataFieldLocationType;
  required: boolean;
  readOnly: boolean;
  order: number;
}
export interface UpdateDataFieldLocationTemplateBody {
  required?: boolean;
  readOnly?: boolean;
  order?: number;
}
export interface UpdateDataFieldOptionTemplateBody {
  value?: string;
  order?: number;
  description?: string;
  icon?: string;
  colors?: string;
  priorityTopics?: string;
  reviewStatements?: string;
  multiselectTooltipValue?: string;
  sepProgressBar?: boolean;
  powerbiLink?: string
}

export interface UpdateDataFieldTemplateBody {
  name?: string;
  description?: string;
  type?: DataFieldType;
}

export type UpdateDataFieldLocationOrderTemplateBody = {
  /** locationType found on the dataField.dataFieldLocation.locationType */
  locationType: DataFieldLocationType;
  payload: DataFieldLocationUpdate[];
};
